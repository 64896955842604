import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Routes, Route, Outlet, Link, Navigate } from "react-router-dom";
import Login from './Login';
import Home from './Home';


function App() {
  const [logged, setLogged] = useState(false);

  return (
    <>
      <header className="bg-custom-blue text-white py-4">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/">
              <img src="wsparcie-biura-logo.png" alt="Wsparcie Biura Logo" className="logo" />
            </Link>
            <Link to="/" className="text-xl font-bold ml-4" style={{fontFamily: "Montserrat, sans-serif"}}>
              WSPARCIE<br />BIURA
            </Link>
          </div>
          <nav className="my-nav">
            <ul className="flex space-x-4">
              <li>
                <a href="/login" className="hover:text-blue-200 text-lg font-bold flex items-center" style={{fontFamily: "Montserrat, sans-serif"}}>
                  <i className="fas fa-user-circle mr-2"></i> Strefa klienta
                </a>
              </li>
            </ul>
          </nav>
        </div>
    </header>

    <Routes>
    <Route path="/">
      <Route index element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="*" element={ <Navigate to="/" /> } />
    </Route>
  </Routes>
  <footer className="bg-custom-blue text-white py-8">
        <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-between">
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                    <div className="flex items-center">
                        <img src="wsparcie-biura-logo.png" alt="Wsparcie Biura Logo" className="h-12 w-12 rounded-full" />
                        <span className="ml-2 text-lg font-bold" style={{fontFamily: "Montserrat, sans-serif"}}>
                            WSPARCIE BIURA
                        </span>
                    </div>
                    <p className="mt-2 text-sm" style={{fontFamily: "Montserrat, sans-serif"}}>
                        Twoje wszechstronne wsparcie administracyjne na każdą sytuację!
                    </p>
                </div>
                <div className="w-full md:w-1/2 mb-4 md:mb-0">
                    <h3 className="text-lg font-bold mb-2">Dane firmy</h3>
                    <p>ANSLAN | NIP: 584-241-79-09 | REGON: 529543975</p>
                </div>
            </div>
            <div className="mt-8 text-center">
                <p>&copy; 2024 Wsparcie Biura. Wszelkie prawa zastrzeżone.</p>
            </div>
        </div>
    </footer>
  </>
);
}

export default App;
