import React, { useState } from "react";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!/\S+@\S+\.\S+/.test(email)) {
            setError("Proszę podać poprawny e-mail.");
            return;
        }
        if (password === "") {
            setError("Proszę podać hasło.");
            return;
        }
        setError("Niepoprawny login lub hasło. Jeżeli uważasz, że dane są prawidłowe, skontaktuj się z naszym Specjalistą, pracującym w Twojej firmie.");
    };

    return (
        <section id="login" className="bg-custom-light-blue py-16">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center text-custom-blue mb-8">Zaloguj się do swojego konta</h2>
                <p className="text-lg text-center mb-8">Wprowadź swoje dane logowania, aby uzyskać dostęp do panelu użytkownika.</p>
                <div className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-md">
                    <form onSubmit={handleSubmit} className="login-form">
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 font-bold mb-2">E-mail firmowy</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-custom-blue"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-gray-700 font-bold mb-2">Hasło</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-custom-blue"
                            />
                        </div>
                        <button type="submit" className="w-full bg-custom-blue text-white py-3 px-6 rounded-lg text-lg hover:bg-blue-400 transition duration-300">Zaloguj</button>
                    </form>
                    {error && <div className="error-message text-red-500 mt-4">{error}</div>}
                </div>
            </div>
        </section>
    );
}

export default Login;