import React from "react";
import { motion } from 'framer-motion';
import { FaCheckCircle, FaClock, FaSync, FaRocket } from 'react-icons/fa';
import ContactForm from './ContactForm';

function Home() {
    return (
        <>

    <main>
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-gradient-to-r from-custom-light-blue to-blue-200 py-20"
        >
            <div className="container mx-auto px-4 text-center">
                <h1 className="text-4xl font-bold text-custom-blue mb-4">Twoje wszechstronne wsparcie administracyjne na każdą sytuację!</h1>
                <p className="text-xl mb-8">Cały zespół ekspertów za cenę jednego specjalisty administracyjnego</p>
                <div className="py-2">
                  <a 
                    href="#kontakt" 
                    className="button-consultation bg-custom-blue text-white rounded-lg hover:bg-blue-400 hover:shadow-lg transition duration-300 font-bold"
                  >
                    Zamów bezpłatną konsultację
                  </a>
                </div>
            </div>
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          id="o-nas"
          className="py-16"
        >
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center text-custom-blue mb-8">Kim jesteśmy?</h2>
                <div className="flex flex-col lg:flex-row items-center lg:items-start">
                    <div className="w-full lg:w-1/3 mb-8 lg:mb-0 lg:mr-8 flex justify-center order-1 lg:order-none">
                        <img 
                          src="office.png" 
                          alt="Zespół Wsparcie Biura" 
                          className="rounded-lg shadow-lg" 
                          style={{
                            height: "300px", 
                            width: "450px",
                            objectFit: "cover",
                            flexShrink: 0
                          }} 
                        />
                    </div>
                    <div className="w-full lg:w-2/3 order-2 lg:order-none">
                        <p className="text-lg mb-4">Wsparcie Biura to zespół wysoko wykwalifikowanych specjalistów, którzy codziennie wspierają rozwój małych firm. Działamy zarówno na miejscu, jak i zdalnie, oferując elastyczne i spersonalizowane rozwiązania, które odpowiadają na Twoje unikalne potrzeby biznesowe.</p>
                        <p className="text-lg mb-4">Dzięki nam zyskujesz dostęp do kompleksowej wiedzy i umiejętności zespołu ekspertów – bez konieczności zatrudniania dodatkowych pracowników i bez ukrytych kosztów.</p>
                        <p className="text-lg font-bold">Wybierz nas i skoncentruj się na rozwoju swojej firmy, a my zadbamy o całą resztę!</p>
                    </div>
                </div>
            </div>
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-custom-light-blue py-16"
        >
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center text-custom-blue mb-8">Dlaczego warto wybrać Wsparcie Biura?</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center">
                        <FaCheckCircle className="text-custom-blue text-5xl mb-4" />
                        <div>
                            <h3 className="text-xl font-bold mb-4">Kompleksowość</h3>
                            <p>Otrzymujesz wsparcie całego zespołu ekspertów – od administracji po IT i finanse – za cenę jednego pracownika.</p>
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center">
                        <FaClock className="text-custom-blue text-5xl mb-4" />
                        <div>
                            <h3 className="text-xl font-bold mb-4">Elastyczność i dostępność</h3>
                            <p>Nasi specjaliści dostosowują harmonogram pracy do Twoich potrzeb. Mogą pracować w Twoim biurze od 1 do 3 dni w tygodniu, a dodatkowo masz pełne wsparcie zdalne przez cały tydzień.</p>
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center">
                        <FaSync className="text-custom-blue text-5xl mb-4" />
                        <div>
                            <h3 className="text-xl font-bold mb-4">Ciągłość działania</h3>
                            <p>Zapewniamy nieprzerwane wsparcie, niezależnie od urlopów, zwolnień chorobowych czy innych absencji.</p>
                        </div>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center">
                        <FaRocket className="text-custom-blue text-5xl mb-4" />
                        <div>
                            <h3 className="text-xl font-bold mb-4">Szybki Start</h3>
                            <p>Jesteśmy gotowi do działania od razu. Możemy rozpocząć współpracę już dzisiaj, a nasza elastyczna polityka umożliwia zakończenie współpracy z tylko jednodniowym okresem wypowiedzenia.</p>
                        </div>
                    </div>
                </div>
            </div>
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          id="uslugi"
          className="py-16 bg-gray-50"
        >
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center text-custom-blue mb-8">Zakres naszych usług administracyjnych</h2>
                <p className="text-lg text-center mb-8">Każda mała firma ma trochę inne potrzeby, które często się zmieniają. Nie musisz wybierać usług - dobierzemy ich zakres do potrzeb Twojej firmy KAŻDEGO DNIA.</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
                        <h3 className="text-xl font-bold mb-4">Kompleksowa obsługa HR i kadr</h3>
                        <p className="mb-4">Od zarządzania dokumentacją pracowniczą i umów, po rekrutację i onboarding nowych pracowników. Zadbamy, by każdy proces HR był zgodny z przepisami RODO i optymalizował efektywność Twojej firmy.</p>
                        <blockquote className="border-l-4 border-custom-blue pl-4 italic bg-blue-50 p-3 text-gray-600">
                            "Rekrutacja i wdrożenie do pracy nowych pracowników zawsze angażowały wiele osób i - jak się okazało - były bardzo słabo oceniane zarówno przez kandydatów, jak i przez zatrudnionych już pracowników. Na szczęście udało się to uporządkować, dzięki czemu nie tylko znacząco skróciliśmy czas poszukiwania nowych osób, ale też zwiększyło się zadowolenie pracowników już pracujących w firmie".
                        </blockquote>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
                        <h3 className="text-xl font-bold mb-4">Finanse i fakturowanie</h3>
                        <p className="mb-4">Współpraca z biurem księgowym, przygotowanie faktur, rejestracja faktur kosztowych, prowadzenie umów z kontrahentami, załatwianie spraw urzędowych, podstawowa analiza finansowa.</p>
                        <blockquote className="border-l-4 border-custom-blue pl-4 italic bg-blue-50 p-3 text-gray-600">
                            "Dopiero specjaliści ze Wsparcie Biura pokazali nam, jak wiele pieniędzy przecieka nam przez palce przez niedotrzymywanie terminów umownych lub nieegzekwowanie zapłaty za usługi, które wykonaliśmy. Po uporządkowaniu listy projektów i uproszczeniu procesu fakturowania i windykacji, uzyskaliśmy dodatkowe kilkanaście tysięcy złotych przychodu miesięcznie".
                        </blockquote>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
                        <h3 className="text-xl font-bold mb-4">Logistyka biurowa</h3>
                        <p className="mb-4">Zamówienia materiałów biurowych, obsługa delegacji, zarządzanie flotą, koordynacja pracy firm sprzątających.</p>
                        <blockquote className="border-l-4 border-custom-blue pl-4 italic bg-blue-50 p-3 text-gray-600">
                            "Mało co tak destabilizuje pracę naszej firmy i wpływa negatywnie na motywację pracowników, jak kończąca się kawa, czy ekipa sprzątająca rozpoczynająca pracę w środku dnia. Na szczęście to już za nami i ponownie możemy wrócić do przetwarzania kawy na kreatywne pomysły, bez obawy że ktoś przeszkodzi nam w najważniejszym momencie procesu twórczego".
                        </blockquote>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
                        <h3 className="text-xl font-bold mb-4">Automatyzacja i digitalizacja</h3>
                        <p className="mb-4">Wdrażanie elektronicznego obiegu dokumentów, digitalizacja procesów, standaryzacja umów itp.</p>
                        <blockquote className="border-l-4 border-custom-blue pl-4 italic bg-blue-50 p-3 text-gray-600">
                            "Nasza firma ma długoletnią tradycję i od zawsze dokumentacja projektowa była prowadzona w wersji papierowej. Dopiero po wdrożeniu elektronicznego obiegu dokumentów, okazało się, jak wiele godzin tygodniowo wszyscy pracownicy tracili na poszukiwaniu potrzebnych dokumentów, odtwarzanie zagubionych i przepisywanie do systemów informatycznych informacji, które były już w tych dokumentach zawarte".
                        </blockquote>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
                        <h3 className="text-xl font-bold mb-4">Wsparcie IT</h3>
                        <p className="mb-4">Konfiguracja i zarządzanie Microsoft 365, Google Workspace, oprogramowaniem firmowym, współpraca z dostawcami sprzętu i oprogramowania.</p>
                        <blockquote className="border-l-4 border-custom-blue pl-4 italic bg-blue-50 p-3 text-gray-600">
                            "Dzięki kompetencjom zespołu specjalistów ze Wsparcia Biura, o połowę zmniejszyliśmy koszty obsługi IT. Obsługę sprzętu komputerowego dalej realizujemy ze wsparciem firmy zewnętrznej, ale wszystkie pozostałe usługi, tj. zarządzanie kontami pracowników, zarządzanie licencjami oprogramowania, czy zarządzanie dostępem do przestrzeni dyskowych, są realizowane w ramach abonamentu Wsparcia Biura".
                        </blockquote>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
                        <h3 className="text-xl font-bold mb-4">Zarządzanie projektami</h3>
                        <p className="mb-4">Organizacja szkoleń i wydarzeń firmowych, usprawnienia procesów wewnątrzfirmowych.</p>
                        <blockquote className="border-l-4 border-custom-blue pl-4 italic bg-blue-50 p-3 text-gray-600">
                            "Nigdy bym nie pomyślała, że przy tak skromnym budżecie na wydarzenia firmowe, jaki mamy, można stworzyć coś takiego, że pracownicy sami przychodzą i pytają, kiedy będzie następne spotkanie!"
                        </blockquote>
                    </div>
                </div>
            </div>
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="py-16 bg-gray-100 bg-opacity-50 bg-pattern"
        >
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center text-custom-blue mb-8">Jak wygląda nasza współpraca?</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div className="text-center">
                        <div className="w-16 h-16 bg-custom-blue text-white rounded-full flex items-center justify-center text-2xl font-bold mx-auto mb-4">1</div>
                        <h3 className="text-xl font-bold mb-2">Diagnoza potrzeb</h3>
                        <p>Dokładnie analizujemy obecne procesy w Twojej firmie, identyfikując kluczowe obszary wymagające wsparcia i optymalizacji.</p>
                    </div>
                    <div className="text-center">
                        <div className="w-16 h-16 bg-custom-blue text-white rounded-full flex items-center justify-center text-2xl font-bold mx-auto mb-4">2</div>
                        <h3 className="text-xl font-bold mb-2">Plan działania</h3>
                        <p>Na podstawie analizy tworzymy propozycję usprawnień i plan współpracy.</p>
                    </div>
                    <div className="text-center">
                        <div className="w-16 h-16 bg-custom-blue text-white rounded-full flex items-center justify-center text-2xl font-bold mx-auto mb-4">3</div>
                        <h3 className="text-xl font-bold mb-2">Wdrożenie</h3>
                        <p>Nasz specjalista administracyjny pracuje w Twojej firmie od 1 do 3 dni w tygodniu, skupiając się na zadaniach, których nie da się zrealizować poza biurem, a nasz zespół zdalny realizuje zadania przez wszystkie dni.</p>
                    </div>
                    <div className="text-center">
                        <div className="w-16 h-16 bg-custom-blue text-white rounded-full flex items-center justify-center text-2xl font-bold mx-auto mb-4">4</div>
                        <h3 className="text-xl font-bold mb-2">Ciągłe wsparcie</h3>
                        <p>Dzięki elastyczności, nasza współpraca dostosowuje się do Twoich potrzeb, zapewniając ciągłość działania i eliminując niepotrzebną biurokrację.</p>
                    </div>
                </div>
            </div>
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          id="kontakt"
          className="bg-custom-light-blue py-16"
        >
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center text-custom-blue mb-8">Zacznijmy współpracę już dziś!</h2>
                <p className="text-lg text-center mb-8">Nie trać czasu na poszukiwanie specjalistów w każdej dziedzinie i oszczędzaj na obsłudze administracyjnej Twojego biura. Wystarczy 5 minut, aby zamówić bezpłatną analizę procesów w Twojej firmie – bez zobowiązań!</p>
                <div className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-md">
                    <ContactForm />
                </div>
            </div>
        </motion.section>
    </main>

    </>

    );
}

export default Home;